// core
import React, { useCallback, useEffect, useState } from 'react'

//api
import API from 'api'

// components
import {
  PageHeader,
  Chip,
  formatDate,
  TableVirtualized,
  Button,
  Dialog,
  Input,
  Icon,
  Loader,
  showToastErr,
} from 'components'
import { Editor } from 'react-draft-wysiwyg'
import { IssueStatus } from './Partials/IssueStatus'
import { IIssue } from 'api/interfaces/IIssue'
import { ISupportTicketStatus } from '../../../api/interfaces/ISupportTicketStatus'
import { Link, useHistory } from 'react-router-dom'
import { ContentState, convertToRaw, EditorState } from 'draft-js'

// libraries
import cx from 'classnames'
import htmlToDraft from 'html-to-draftjs'
import { changeTitle } from '../../routes'

// translation
import { t } from 'i18n'

//styles
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import css from './Issues.module.scss'
import cssTab from '../../../components/containers/Tabs/Tabs.module.scss'
import draftToHtml from 'draftjs-to-html'
import { humanFileSize } from './IssueDetail'
import { AuthService } from 'services/AuthService'
import ReCAPTCHA from "react-google-recaptcha";

interface ILaravelPaginatorMeta {
  total: number
  from: number
  to: number
  current_page: number
  per_page: number
  last_page: number
}

interface ILaravelPaginatorLinks {
  first: string
  last: string
  next: string | null
  prev: string | null
}

interface ILaravelPaginatorResponse {
  data: []
  links: ILaravelPaginatorLinks
  meta: ILaravelPaginatorMeta
}

interface ILaravelResponse {
  id: number
}

const toolbar = {
  options: ['inline', 'list', 'link', 'history'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic'],
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
  },
  link: {
    inDropdown: false,
    defaultTargetOption: '_blank',
    options: ['link', 'unlink'],
  },
  history: {
    inDropdown: false,
    options: ['undo', 'redo'],
  },
}

export const Issues = () => {
  const [tab, setTab] = useState<'active' | 'history'>('active')
  const [issues, setIssues] = useState<IIssue[]>([])
  const [meta, setMeta] = useState<ILaravelPaginatorMeta>()
  const [firstLoad, setFirstLoad] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadNext, setLoadNext] = useState<boolean>(false)

  const recapthaRef = React.useRef<ReCAPTCHA>(null);

  const getDefaultState = useCallback(() => {
    const { contentBlocks, entityMap } = htmlToDraft(`
<p><strong>Current Behavior</strong></p>
<p> </p>
<p><strong>Expected Behavior</strong></p>
<p> </p>
<p><strong>Steps to Reproduce the Problem</strong></p>
<p> </p>
<p><strong>Environment</strong></p>
<ul>
<li>Webswing Version:</li>
<li>Platform/OS:</li>
<li>Browser:</li>
</ul>
`)
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    return EditorState.createWithContent(contentState)
  }, [])

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false)
  const [issueTitle, setIssueTitle] = useState<string>('')
  const [issueMessage, setIssueMessage] = useState<EditorState>(() => getDefaultState())
  const [files, setFiles] = useState<File[]>([])
  const [isBigger, setIsBigger] = useState<boolean>(false)

  const processFiles = useCallback(
    (e: any) => {
      const newFiles = [...files]
      for (const f of e.target.files) {
        if (files.filter(ef => ef.name === f.name && ef.size === f.size).length === 0) {
          newFiles.push(f)
        }
        if (f.size > 23000000) {
            setIsBigger(true);
        }
      }
      setFiles(newFiles)
    },
    [files, setFiles]
  )

  const history = useHistory()

  changeTitle('Support')

  const loadIssues = useCallback(
    (page = 1, type: 'ListActive' | 'ListHistory' = 'ListActive') => {
      if(!AuthService.hasPermission('issues', 'view')) return;
      setLoading(true)
      API.IssuesEndpoint[type](page).then(res => {
        // if (res?.status === 403) {
        //   history.push('/dashboard')
        // }
        if (res?.success) {
          const response = res.content as ILaravelPaginatorResponse
          setMeta(response.meta)
          if (page > 1) {
            setIssues(issues => [...issues, ...(response.data as IIssue[])])
          } else {
            setIssues(response.data as IIssue[])
          }
        }

        setLoadNext(false)
        setLoading(false)
      })
    },
    [history]
  )

  const onEditorStateChange = useCallback(
    editorState => {
      setIssueMessage(editorState)
    },
    [setIssueMessage]
  )

  const save = useCallback(() => {
    setIsModalLoading(true)

    const body = {
      title: issueTitle,
      html: draftToHtml(convertToRaw(issueMessage.getCurrentContent())),
      captha: '',
      files: files || [],
    }

    if(AuthService.hasPermission('issues', 'view')) {
      createTicket(body);
    } else {
      if (recapthaRef) {
        recapthaRef.current!.executeAsync()
        .then(token => {
          recapthaRef.current!.reset();
          if(token) {
              body.captha = token;
              createTicket(body)
          } else {
              showToastErr('reCAPTCHA verification failed!')
              setIsModalLoading(false)
            }
        })
        .catch(() => {
          setIsModalLoading(false)
          showToastErr('reCAPTCHA verification failed!')
        })
      }
    }

  }, [issueMessage, issueTitle, files, history, recapthaRef]);

  useEffect(() => {
    if (!loading && loadNext && meta && meta.current_page < meta.last_page) {
      loadIssues(meta.current_page + 1, 'active' === tab ? 'ListActive' : 'ListHistory')
    }
  }, [loadNext, loading, meta, tab, loadIssues])

  useEffect(() => {
    if (!loading && !firstLoad) {
      loadIssues()
      setFirstLoad(true)
    }
  }, [firstLoad, loading, loadIssues])

  const nextPage = useCallback(() => {
    setLoadNext(true)
  }, [])

  const createTicket = (body: any) => {
    API.IssuesEndpoint.NewTicker(body).then(res => {
      setIsModalLoading(false)
      if (res?.success) {
        setModalOpen(false)
        if(AuthService.hasPermission('issues', 'view')) {
          history.push(`support/${(res.content as ILaravelResponse).id}`)
        } else {
          history.push('/dashboard')
        }
      } else {
        showToastErr("Error while submitting the ticket. Please try again later or send us an email to support@webswing.org.")
      }
    })
    .catch((e) => {
      setIsModalLoading(false)
      showToastErr("Error while submitting the ticket. Please try again later or send us an email to support@webswing.org.")
    })
  }

  const changeTab = useCallback(
    (tab: 'active' | 'history') => {
      setTab(tab)
      loadIssues(1, 'active' === tab ? 'ListActive' : 'ListHistory')
    },
    [setTab, loadIssues]
  )

  const toggleDialog = useCallback(() => {
    setModalOpen(!modalOpen)
  }, [modalOpen])

  const removeFile = useCallback(
    (index: number) => {
      const newFiles = [...files]
      newFiles.splice(index, 1)
      setIsBigger(newFiles.filter(f => f.size > 23000000 ).length > 0? true:false)
      setFiles(newFiles)
    },
    [files, setFiles]
  )

  if(AuthService.hasPermission('issues', 'view')) {
    return (
      <>
        <Dialog
          bOpen={modalOpen}
          title="Open new Support ticket or Report a bug"
          labelOk="Submit"
          className={css.modal}
          onToggle={toggleDialog}
          disabledButtons={isModalLoading}
          onConfirm={save}>
          {
            isModalLoading ? (
              <div className={css.modalLoading}>
                <Loader.Spinner />
              </div>
            ) : (
            <>
              <Input
                value={issueTitle}
                label="Subject"
                onChange={e => setIssueTitle(e.currentTarget.value)}
              />
              <Editor
                editorState={issueMessage}
                toolbar={toolbar}
                onEditorStateChange={onEditorStateChange}
              />
              <div className={css.files}>
                {files.map((f: File, i: number) => (
                  <div key={`${f.name}-${f.size}`}>
                    <Button.UI className={cx(cssTab.wTab, css.bug)} color="danger" size="small">
                      <span>
                        {f.name} ({humanFileSize(f.size)})
                      </span>
                      <button onClick={() => removeFile(i)}>
                        <Icon name="close" size="small" color="white" />
                      </button>
                    </Button.UI>
                  </div>
                ))}
              </div>
              <div>
                  <Button
                  color="neutral"
                  label="Attach files"
                  size="small"
                  onClick={() => (document.querySelector('input#files') as HTMLElement).click()}
                />
              </div>
                <Chip className={css.Valchip} label="Additional large files can be uploaded in the ticket details after ticket submission." />
                {isBigger &&  <Chip className={css.Valchip} color='neutral' label="There is one or more file/s that are too large to be sent as attachment." /> }
                <input
                multiple
                type="file"
                style={{ display: 'none' }}
                id="files"
                onChange={processFiles}
              />
            </>
            )
          }
        </Dialog>
        <div style={{ minWidth: 0, maxWidth: '100%' }}>
          <PageHeader title={t.ISSUES_SUPPORT} />
          <div className={css.buttons}>
            <Button.UI
              className={cx(cssTab.wTab, 'active' === tab && cssTab.active)}
              onClick={() => changeTab('active')}>
              <span>Active</span>
            </Button.UI>
            <Button.UI
              className={cx(cssTab.wTab, 'history' === tab && cssTab.active)}
              onClick={() => changeTab('history')}>
              <span>History</span>
            </Button.UI>
            <div style={{ flex: 1 }} />
              <Button size="small" label="Open ticket" style={{marginRight:'5px'}} onClick={toggleDialog} />
              <Button size="small" color="danger" label="Report bug" onClick={toggleDialog} />
          </div>
          <div style={{ overflowX: 'auto' }}>
            <TableVirtualized
              collection={issues}
              minTableWidth={1150}
              bLoading={loading}
              threshold={500}
              columns={[
                {
                  component: supportTicketStatus => (
                    <div className={css.status}>
                      <IssueStatus color={(supportTicketStatus as ISupportTicketStatus).name} />
                      {(supportTicketStatus as ISupportTicketStatus).name}
                    </div>
                  ),
                  dataKey: 'support_ticket_status',
                  label: 'Status',
                  minWidth: 110,
                  align: 'start',
                },
                {
                  component: (ticket, row) => (
                    <Link className={css.link} to={`/support/${row.id}`}>
                      {ticket}
                    </Link>
                  ),
                  dataKey: 'ticket_number',
                  label: 'Ticket',
                  minWidth: 100,
                  align: 'start',
                },
                {
                  component: (responseUntil, row) => (
                    <div className={css.dateChip}>
                      <span>
                        {formatDate(responseUntil as string, { format: 'DD.MM.YYYY HH:mm' })}
                      </span>
                      <Chip
                        className={css.chip}
                        label={((row as unknown) as IIssue).level_response_time_limit}
                      />
                    </div>
                  ),
                  dataKey: 'response_until',
                  label: 'Response limit',
                  minWidth: 210,
                  align: 'start',
                },
                {
                  component: (resolveUntil, row) => (
                    <div className={css.dateChip}>
                      <span>
                        {formatDate(resolveUntil as string, { format: 'DD.MM.YYYY HH:mm' })}
                      </span>
                      <Chip
                        className={css.chip}
                        label={((row as unknown) as IIssue).level_resolve_time_limit}
                      />
                    </div>
                  ),
                  dataKey: 'resolve_until',
                  label: 'Resolution limit',
                  minWidth: 250,
                  align: 'start',
                },
                {
                  component: createdAt => (
                    <span>{formatDate(createdAt as string, { format: 'DD.MM.YYYY HH:mm' })}</span>
                  ),
                  dataKey: 'created_at',
                  label: 'created',
                  minWidth: 125,
                  align: 'start',
                },
                {
                  component: subject => <div className={css.subject}>{subject}</div>,
                  dataKey: 'subject',
                  label: 'Subject',
                  minWidth: 200,
                  align: 'start',
                },
              ]}
              onReachEnd={nextPage}
              onRowClick={info => {
                history.push(`/support/${info?.rowData.id}`)
              }}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div style={{ minWidth: 0, maxWidth: '100%' }}>
      <PageHeader title="Report an Issue" />
      <div style={{ overflowX: 'auto', position: 'relative' }}>
        {
          isModalLoading ? (
            <div className={css.modalLoading}>
              <Loader.Spinner />
            </div>
          ): (
            <>
            <Input
              value={issueTitle}
              label="Subject"
              onChange={e => setIssueTitle(e.currentTarget.value)}
            />
    
            <Editor
              editorState={issueMessage}
              toolbar={toolbar}
              onEditorStateChange={onEditorStateChange}
            />

            
            <Button size="small" color="danger" label="Submit an Issue" bDisabled={isModalLoading || issueTitle === ''} onClick={save} />
            </>
          )
        }
      </div>
      <ReCAPTCHA
        ref={recapthaRef}
        size="invisible"
        sitekey="6LdFosIkAAAAABAQ88_MBQRCmA81OHY7PzJ7QsYr"
      />
    </div>
  )
}
